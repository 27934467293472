
//
// Configirations & Initialize Plugins
//

//
// Table of content:
// 
// 1. Sidebar
// 2. Dragula

"use strict";

$(function(){

	// 1. Sidebar
	Sidebar();

	// 2. Dragula
	var dragulaObj = dragula( $('.c-pipeline').toArray(), {});

});
